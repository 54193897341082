import React, { useContext, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import orderImage from "./../assets/image/svg/register-order.svg"
import { Button, Section } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';


const SuccessOrderPage = () => {
    const gContext = useContext(GlobalContext);
    const order_id = gContext.goGetRegistrationData()?.orderId;
    const order_value = gContext.goGetRegistrationData()?.totalPrice;

    useEffect(() => {
        gContext.hideFullScreenLoader();
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
    }, [])

    return (
        <>
            <Helmet>
                <script src={`https://secure.adnxs.com/px?id=1716401&seg=34711278&other=${order_value}&order_id=${order_id}&t=2`} type="text/javascript"></script>
            </Helmet>
            <Section className="green-bg text-center">
                <Container>
                    {
                        (gContext.goGetRegistrationData()?.skipBooking || gContext.goGetRegistrationData()?.noSlotsAvailable) ?
                            <Row className="justify-content-center">
                                <Col lg={8} md={10} sm={8}>
                                    <img src={orderImage} className="mt-30 mb-30" />
                                    <h2>Success!<br />You placed your order!</h2>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem('registrationData');
                                            gContext.goResetRegistrationData();
                                            navigate('/');
                                        }}
                                        className="mt-50 light-bg"
                                    >Return to homepage</Button>
                                </Col>
                            </Row>
                            :
                            <Row className="justify-content-center">
                                <Col lg={8} md={10} sm={8}>
                                    <img src={orderImage} className="mt-30 mb-30" />
                                    <h2>Success! You placed your order<br />and your installation is now scheduled.</h2>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem('registrationData');
                                            gContext.goResetRegistrationData();
                                            navigate('/');
                                        }}
                                        className="mt-50 light-bg"
                                    >Return to homepage</Button>
                                </Col>
                            </Row>
                    }
                </Container>
            </Section>
        </>
    )
}

export default SuccessOrderPage